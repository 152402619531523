<template>
	<CHeader fixed with-subheader light>
		<div class="container">
			<div class="row">
				<div class="col-md-12 bg-white p-0">
					<nav class="navbar navbar-expand-sm navbar-light w-100">
						<a class="navbar-brand" to="/"><img src="img/logo-kementan.png" style="width: 60px;" alt=""></a><b>Kementrian Pertanian <br> Republik Indonesia</b>
						<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span class="navbar-toggler-icon"></span>
						</button>
						<div class="collapse navbar-collapse " id="navbarSupportedContent">
							<form class="form-inline ml-auto mt-3" action="#">
							    <div class="input-group">
									<input type="text" class="form-control" v-model="kode_permohonan" placeholder="Contoh : Horti-123">
									<div class="input-group-append">
										<span class="input-group-text" id="basic-addon2" v-on:click="searchPermohonan"><i class="fa fa-search"></i></span>
									</div>
								</div>
							</form>
							<div>
								<router-link :to="{name: 'Login'}" class="btn btn-outline-success mx-1">Masuk</router-link>
								<router-link :to="{name: 'Register'}" class="btn btn-success mx-1">Registrasi</router-link>
							</div>
						</div>
					</nav>
				</div>
			</div>
		</div>
		<template>
		    <div>
		        <CModal title="Catatan" color="btn-primary" :show.sync="modalSearch">
		        	<table>
		        		<tr>
		        			<th>Kode Permohonan</th>
		        			<td> : </td>
		        			<td>
		        				<template>
									{{ $evaluate('dataPermohonan?.kode') }}
								</template>
		        			</td>
		        		</tr>
		        		<tr>
		        			<th>Nama Perusahaan</th>
		        			<td> : </td>
		        			<td>
		        				<template>
									{{ $evaluate('dataPermohonan?.perusahaan') }}
								</template>
			        		</td>
		        		</tr>
		        		<tr>
		        			<th>Tanggal Pengajuan</th>
		        			<td> : </td>
		        			<td>
		        				<template>
									{{ $evaluate('dataPermohonan?.tgl_pengajuan') }}
								</template>
			        		</td>
		        		</tr>
		        		<tr>
		        			<th>Tanggal Verifikasi</th>
		        			<td> : </td>
		        			<td>
		        				<template>
									{{ $evaluate('dataPermohonan?.pr_tgl_verifikasi') }}
								</template>
			        		</td>
		        		</tr>
		        		<tr>
		        			<th>Status</th>
		        			<td> : </td>
		        			<td>
		        				<template>
                                    <CBadge :color="getBadge(dataPermohonan.status_permohonan)" v-if="dataPermohonan.status_permohonan == 'Pengajuan Permohonan'">
                                        <div>
                                            Draft
                                        </div>
                                    </CBadge>
                                    <CBadge :color="getBadge(dataPermohonan.status_permohonan)" v-else>
                                        <div>
                                            {{dataPermohonan.status_permohonan}}
                                        </div>
                                    </CBadge>
                                </template>
			        		</td>
		        		</tr>
		        	</table>
		            <template #footer>
		                <CButton @click="modalSearch = false" color="secondary">Tutup</CButton>
		            </template>
		        </CModal>
		    </div>
		</template>
	</CHeader>
</template>
<script>
	export default {
		name: "TheHeader",
		data() {
			return {
				dataPermohonan: [],
				kode_permohonan: '',
				modalSearch: false
			};
		},
		methods: {
			$evaluate: param => eval('this.'+param),
			searchPermohonan() {
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Mohon tunggu sebentar...",
					showConfirmButton: false,
				});
				axios.get(this.apiLink + "api/search_permohonan", {
	            	params:{
	            		kode: this.kode_permohonan
	            	}
	            })
	            .then((response) => {
	            	this.dataPermohonan = response.data.data[0];
	            	this.modalSearch = true;
	            	swal.close();
	                // console.log(this.dataPermohonan)
	            })
	            .catch((error) => {
	            	console.log(error.response.data);
	            	if (error) {

		            	Swal.fire({
		            		icon: 'error',
							// title: '<i class="fa fa-refresh fa-spin"></i>',
							text: error.response.data.data.error,
						});
	            	}
	            });
			},
			getBadge(status) {
				switch (status) {
					case "draft":
						return "bg-dark";
					case "Menunggu Perbaikan":
						return "warning";
					case "Ditolak":
						return "danger";
					case "Selesai":
						return "success";
					case "ajukan":
						return "info";
                    case "Dibatalkan":
						return "danger";
					default:
						return "info";
				}
			},
		}
	};
</script>